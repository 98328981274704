import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Star } from "../Images/svg/star.svg";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import pic from "../Images/photos/form.png";
import cancel from "../Images/svg/Cancel.svg";
import tick from "../Images/svg/ticked.svg";
// import Select from "react-select";
// import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

// const options = [
//   { value: "Activity Sponsor - 4 million Naira", label: "Activity Sponsor - 4 million Naira" },
//   { value: "Silver Sponsor - 5 to 8 million Naira", label: "Silver Sponsor - 5 to 8 million Naira" },
//   { value: "Gold Sponsor - 10 to 15 million Naira", label: "Gold Sponsor - 10 to 15 million Naira" },
//   { value: "Headline Sponsor - 20 million Naira", label: "Headline Sponsor - 20 million Naira" },
//   { value: "Others ", label: "Others " },
// ];
function Sponsor() {
  const [save, setSave] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    company_name: Yup.string().required("company's name is required"),
    official_email: Yup.string().email("Invalid email address").required("Email is required"),
    phone_number: Yup.string().matches(/^\d+$/, "Phone number must contain only digits").required("Phone number is required"),
    // sponsorship_category: Yup.string().required("Please select a sponsorship category"),
  });
  const formik = useFormik({
    initialValues: {
      model: "sponsors",
      company_name: "",
      official_email: "",
      phone_number: "",
      // sponsorship_category: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = {
        model: "sponsors",
        company_name: values.company_name,
        official_email: values.official_email,
        phone_number: values.phone_number,
        // sponsorship_category: values.sponsorship_category,
      };
      console.log(formData);
      try {
        // const res = await fetch("https://www.thebabyconvention.com/wbe/api/add_contact.php", {
        const res = await fetch("https://www.thebabyconvention.com/wbe/api/add_sponsors.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        // const response = await axios.post("https://www.thebabyconvention.com/wbe/api/add_sponsors.php", formData);
        console.log(formData);
        console.log(res);
        setLoading(false);
        if (res.ok) {
          setSave(true);
          const responseData = await res.json();
          setResponseMessage(responseData.responsemessage || "Registration successful!");
          // formik.resetForm(); // Reset form fields
          // setTimeout(() => {
          //   navigate("/"); // Redirect to home page after a short delay to ensure form reset
          // }, 2000);
        } else {
          setResponseMessage("Registration failed. Please try again.");
        }
      } catch (error) {
        setLoading(false);
        setResponseMessage("An error occurred. Please try again later.");
      }
    },
  });
  // const handleRadioChange = (event) => {
  //   formik.setFieldValue("sponsorship_category", event.target.value);
  // };
  return (
    <>
      <div className=" w-full flex justify-between items-start">
        <div style={{ backgroundImage: ` url(${pic})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "rgba(254, 135, 52) 60%" }} className="100:hidden xl:flex w-[100vh] fixed top-0 left-0  h-full">
          <div className=" w-full bg-[#FE8734]/60 h-full">
            <div className=" w-full   flex justify-end items-end h-full" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.7) 100%)` }}>
              <div className="p-10 sm:p-[50px] md:p-[75px] lg:p-[50px] w-full gap-[48px] flex flex-col justify-start items-start">
                <Star />
                <div className="flex-col justify-start items-center gap-6 inline-flex">
                  <h1 className="self-stretch text-white text-[32px] sm:text-[40px] md:text-[48px] lg:text-[64px] font-semibold font-playfair leading-tight">Join the Baby Convention Today!</h1>
                  <p className="self-stretch text-[#cfcfd2] text-lg font-normal font-openSans leading-[27px]">
                    Don't miss out on the ultimate event for expectant parents and families with young children! Our Baby Convention offers a wealth of resources, expert advice, product showcases, and fun activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" h-fit pb-[50px] flex flex-col justify-start items-center gap-[62px] w-full 100:pl-[40px] xl:pl-[105vh] pr-[40px]">
          <div className=" h-[100px] left-0 top-0 bg-white/40 w-full 100:px-[50px] xl:px-0  100:fixed xl:static   flex  justify-between items-center">
            <Link to="/options">
              <div className="w-fit flex justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={arrow} alt="back" />
                </div>
                <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
              </div>
            </Link>
            <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
              <img className="w-full" src={logo} alt="The Baby Convention" />
            </div>
            <div className="w-[60px] 100:hidden md:block"></div>
          </div>
          <div className="sm:w-[450px] 100:w-full 100:mt-[100px] xl:mt-0 flex-col justify-start items-start gap-8 inline-flex">
            <h3 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-playfair">Sign up</h3>
            <form onSubmit={formik.handleSubmit} className=" w-full   pb-[10px]  flex-col justify-start items-start gap-4 inline-flex">
              <label className="flex-col w-full  justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Name of company">
                Name of company
                <input
                  type="name"
                  placeholder="Name of company"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="company_name"
                  {...formik.getFieldProps("company_name")}
                />
                {formik.touched.company_name && formik.errors.company_name ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.company_name}</div> : null}
              </label>

              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                Official Email
                <input
                  type="email"
                  placeholder="Email Address"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="official_email"
                  {...formik.getFieldProps("official_email")}
                />
                {formik.touched.official_email && formik.errors.official_email ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.official_email}</div> : null}
              </label>
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Phone number">
                Phone number
                <input
                  type="tel"
                  placeholder="Phone number"
                  inputMode="numeric"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="phone_number"
                  {...formik.getFieldProps("phone_number")}
                />
                {formik.touched.phone_number && formik.errors.phone_number ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.phone_number}</div> : null}
              </label>

              {/* <div className="flex-col w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Sponsorship Category">
                Sponsorship Category
                <div className="flex flex-col gap-4">
                  {options.map((option) => (
                    <label key={option.value} className=" flex justify-start items-center gap-[12px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor={option.value}>
                      <input
                        className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                        type="radio"
                        name="sponsorship_category"
                        value={option.value}
                        checked={formik.values.sponsorship_category === option.value}
                        onChange={handleRadioChange}
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
                {formik.touched.sponsorship_category && formik.errors.sponsorship_category ? <div className="text-red-400 text-[14px] font-openSans font-medium">{formik.errors.sponsorship_category}</div> : null}
              </div> */}
              <button type="submit" className="w-full mt-[24px]">
                <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">{isLoading ? "Submitting..." : " Submit"}</div>
              </button>
            </form>
          </div>
        </div>
      </div>
      {save && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="w-full px-2 justify-end items-center inline-flex">
                <div onClick={() => setSave(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>

              <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
                <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                  <img src={tick} alt="tick" />
                </div>
                <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                  <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Successful</h3>
                  <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{responseMessage}</p>
                </div>
                <div className=" w-full flex justify-center items-center">
                  <button>
                    <div
                      onClick={() => {
                        setSave(false);
                        setLoading(false);
                        formik.resetForm(); // Reset form fields
                        window.scrollTo(0, 0);
                        navigate("/");
                      }}
                      className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"
                    >
                      Ok
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Sponsor;
