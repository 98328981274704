import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import { Link } from "react-router-dom";

function VendorInstruction() {
  return (
    <>
      <div className=" w-full flex flex-col justify-start items-center px-[10px] py-[40px] ">
        <div className=" h-[100px] bg-white/40 w-full top-0 left-[40px] 100:pr-[70px] right-[40px] flex fixed justify-between items-center">
          <Link onClick={() => window.scrollTo(0, 0)} to="/options">
            <div className="w-fit flex justify-center items-center gap-2">
              <div>
                <img className="w-full" src={arrow} alt="back" />
              </div>
              <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
            </div>
          </Link>
          <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className="w-[120px] 100:hidden md:block"></div>
        </div>
        <div className="h-fit 100:w-[85%]  sm:w-[498px] mt-[130px] p-6 bg-[#fff6ed] rounded-3xl shadow flex-col justify-start items-start gap-2.5 inline-flex">
          <div className="w-full  flex-col justify-start items-start gap-8 inline-flex">
            <div className=" flex-col justify-start items-center gap-2 inline-flex">
              <h2 className="self-stretch text-center text-[#3c3b3e] text-[32px] font-semibold font-playfair">Registration Closed</h2>
              <p className=" w-[90%] text-center text-[#6b6a70] text-sm font-normal font-openSans">
                Unfortunately Registration is closed. Do visit our event page for upcoming events planned for the future{" "}
              </p>
            </div>
            
            
            
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorInstruction;
