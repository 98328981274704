import { useEffect, useState } from "react";
import Header from "../Header";
import vid from "../../Images/photos/videoPlaceholder.png";
import play from "../../Images/svg/play.svg";
import a from "../../Images/photos/blue1.png";
import b from "../../Images/photos/blue2.png";
import c from "../../Images/photos/blue3.png";
import d from "../../Images/photos/pink1.png";
import e from "../../Images/photos/pink2.png";
import f from "../../Images/photos/pink3.png";
import g from "../../Images/photos/yellow1.png";
import h from "../../Images/photos/yellow2.png";
import i from "../../Images/photos/yellow3.png";
import j from "../../Images/photos/orange1.png";
import k from "../../Images/photos/orange2.png";
import l from "../../Images/photos/orange3.png";
import m from "../../Images/photos/blu1.png";
import n from "../../Images/photos/blu2.png";
import o from "../../Images/photos/blu3.png";
import p from "../../Images/photos/pin1.png";
import q from "../../Images/photos/pin2.png";
import r from "../../Images/photos/pin3.png";
import s from "../../Images/photos/yello1.png";
import t from "../../Images/photos/yello2.png";
import u from "../../Images/photos/yello3.png";
import v from "../../Images/photos/orang1.png";
import w from "../../Images/photos/orang2.png";
import x from "../../Images/photos/orang3.png";
import NewsLetter from "./NavbarComponents/NewsLetter";
import Footer from "./NavbarComponents/Footer";
import up from "../../Images/svg/arrowUp.svg";

function Products() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  const blue1 = [
    {
      picture: a,
      product: "Deluxe Crib",
      price: "₦299.99",
    },
    {
      picture: b,
      product: "Organic Cotton Crib Sheets",
      price: "₦299.99",
    },
    {
      picture: c,
      product: "Nursery Rhymes",
      price: "₦299.99",
    },
    {
      picture: t,
      product: "Nursery Rhymes",
      price: "₦299.99",
    },
  ];
  const pink1 = [
    {
      picture: d,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: e,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: f,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const yellow1 = [
    {
      picture: g,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: h,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: i,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const orange1 = [
    {
      picture: j,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: k,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: l,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const blu1 = [
    {
      picture: m,
      product: "Deluxe Crib",
      price: "₦299.99",
    },
    {
      picture: n,
      product: "Organic Cotton Crib Sheets",
      price: "₦299.99",
    },
    {
      picture: o,
      product: "Nursery Rhymes",
      price: "₦299.99",
    },
  ];
  const pin1 = [
    {
      picture: p,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: q,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: r,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const yello1 = [
    {
      picture: s,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: t,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: u,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const orang1 = [
    {
      picture: v,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: w,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: x,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  return (
    <>
      <Header />
      <div className="h-fit flex-col w-full 100:px-[32px] md:px-16 py-8  justify-start items-center gap-2.5 inline-flex">
        <div className=" flex-col py-[32px] w-full justify-center items-center gap-4 inline-flex">
          <h2 className="lg:w-[938px] 100:w-full  text-center text-[#3c3b3e] 100:text-[40px] md:text-[64px] font-semibold font-playfair">
            Featured{" "}
            <span className="half-bg-text">
              <h3 className="z-50 relative">Products</h3>
            </span>{" "}
            for You and Your Baby
          </h2>
          <p className="lg:w-[766px] 100:w-full md:w-[650px] text-center text-[#6b6a70] 100:text-[16px] md:text-[20px] leading-[28px] font-normal font-openSans">
            Welcome to our Featured Products page! We've curated top products for you and your baby. From nursery furniture and toys to clothing, feeding essentials, and more, each item is chosen for quality, functionality, and value. Explore our
            categories and find the best for your family.
          </p>
        </div>
        <div className=" w-full flex justify-center items-center">
          <div className="lg:w-[1000px] 100:w-full 100:h-[30vh] md:w-[85%] md:h-[350px] lg:h-[457px] p-6 bg-[#fff6ed] rounded-2xl justify-start items-center gap-2.5 inline-flex">
            <img alt="" className="grow shrink basis-0 self-stretch rounded-2xl" src={vid} />
            <div className="w-[128.46px] flex justify-center items-center  h-[128.46px] 100:left-[34%] md:left-[45%] absolute bg-black/25 rounded-[66.91px] shadow">
              <img alt="" className="" src={play} />
            </div>
          </div>
        </div>
        <div className=" 100:w-full 1xl:w-[1290px]  h-fit 100:px-0 lg:p-6 flex-col justify-start items-start gap-4 flex">
          {/* NURSERY */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Nursery</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {blue1.map((item, id) => (
                  <div key={id} className=" 100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#dff4ff] rounded-2xl shadow-custom border-2 border-[#41cffe] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* TOYS */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Toys</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {pink1.map((item, id) => (
                  <div key={id} className="100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#fae9f2] rounded-2xl shadow-customPink border-2 border-[#EA79AF] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* CLOTHINGS */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Clothings</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {yellow1.map((item, id) => (
                  <div key={id} className="100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#FEF1C7] rounded-2xl shadow-customYellow border-2 border-[#FBB724] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* FEEDING */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Feeding</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {orange1.map((item, id) => (
                  <div key={id} className="100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#FFEBD4] rounded-2xl shadow-customOrange border-2 border-[#FE8734] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Health & Safety */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Health & Safety</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {blu1.map((item, id) => (
                  <div key={id} className=" 100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#dff4ff] rounded-2xl shadow-custom border-2 border-[#41cffe] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Travel Gear */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Travel Gear</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {pin1.map((item, id) => (
                  <div key={id} className="100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#fae9f2] rounded-2xl shadow-customPink border-2 border-[#EA79AF] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Bath & Potty */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Bath & Potty</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {yello1.map((item, id) => (
                  <div key={id} className="100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#FEF1C7] rounded-2xl shadow-customYellow border-2 border-[#FBB724] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Books & Media */}
          <div className="w-full  flex-col justify-start item-start gap-4 flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Books & Media</h3>
            <div className=" overflow-x-scroll w-full py-[20px] px-[10px]">
              <div className="w-fit flex justify-start items-start gap-[32px] ">
                {orang1.map((item, id) => (
                  <div key={id} className="100:w-[310px] sm:w-[410.67px] h-[321px] px-2.5 pt-2.5 pb-4 bg-[#FFEBD4] rounded-2xl shadow-customOrange border-2 border-[#FE8734] flex-col justify-start items-center gap-6 flex">
                    <div className=" w-full h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
      {showButton && (
        <>
        <div onClick={scrollToTop} className="h-10 shadow z-40 100:right-[40px] top-[80vh] lg:right-[80px] p-2.5 cursor-pointer fixed bg-[#dff4ff] rounded-[100px] justify-center items-center gap-2.5 inline-flex">
            <img src={up} alt="arrow" />
          </div>
        </>
      )}
    </>
  );
}

export default Products;
