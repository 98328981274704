import { Link, useLocation, useParams } from "react-router-dom";
import logo from "../../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../../Images/svg/arrowleft.svg";
import { ReactComponent as Pending } from "../../Images/svg/pending.svg";
import { ReactComponent as Reject } from "../../Images/svg/reject.svg";
import { ReactComponent as Completed } from "../../Images/svg/approve.svg";
import { useEffect, useState } from "react";
import axios from "axios";

const QrCodeDetails = () => {
  const [data, setData] = useState(null);
  const [isSelected, setIsSelected] = useState(null);
  const { id, user, booth } = useParams();
  useEffect(() => {
    // console.log(user, id);
    if (user && id && booth) {
      const dataSend = {
        model: user === "participants" ? "participants" : "vendors",
        id: id,
        user_id: 1, // Replace this with the actual user ID if needed
        ...(user !== "participants" && { booth: booth }), // Only include booth if user is not a participant
      };

      axios
        .post("https://thebabyconvention.com/wbe/api/get_all.php", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("is repsone", res.data);
          if (res.data.length > 0) {
            const selectedItem = res.data.find((item) => item.id === id);
            if (selectedItem) {
              setIsSelected(selectedItem);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("Missing user or id in query parameters");
    }
  }, [user, id, booth]);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center px-[10px] py-[40px]">
        <div className="h-[100px] bg-white/40 w-full top-0 left-[40px] 100:pr-[70px] right-[40px] flex fixed justify-between items-center">
          <Link onClick={() => window.scrollTo(0, 0)} to="/home">
            <div className="w-fit flex justify-center items-center gap-2">
              <div>
                <img className="w-full" src={arrow} alt="home" />
              </div>
              <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
            </div>
          </Link>
          <div className="100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className="w-[120px] 100:hidden md:block"></div>
        </div>

        <div className="h-fit 100:w-[85%] sm:w-[498px] mt-[130px] p-6 bg-[#fff6ed] rounded-3xl shadow flex-col justify-start items-start gap-2.5 inline-flex">
          {user === "vendors" && (
            <div className="flex-col w-full justify-start items-start gap-[24px] inline-flex">
              <div className="self-stretch flex-col justify-center items-center gap-2 flex">
                <h4 className="text-[#3c3b3e] text-2xl font-semibold font-openSans">{isSelected?.vendor_name}</h4>
              </div>
              <div className="w-full h-px bg-[#e6e6e7]" />
              <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Vendor Category: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.vendor_category}</span>
                </h4>
                <div className="text-[#6b6a70] text-[14px] font-semibold font-openSans">
                  Booth Type: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.booth_type || booth}</span>
                </div>
                <div className="text-[#6b6a70] text-[14px] font-semibold font-openSans">
                  Instagram: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.instagram_handle}</span>
                </div>
              </div>
              <div className="w-full h-px bg-[#e6e6e7]" />
              <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Vendor Email: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.official_email}</span>
                </h4>
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Phone Number: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.official_phone_number}</span>
                </h4>
              </div>
              <div className="w-full h-px bg-[#e6e6e7]" />
              <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="text-[#6b6a70] flex items-center justify-start gap-[6px] text-[14px] font-semibold font-openSans">
                  Status:{" "}
                  <div className={`pl-1.5 pr-2 py-0.5 mix-blend-multiply rounded-2xl justify-center items-center gap-1 flex ${isSelected?.status === "approved" ? "bg-[#ebfdf2]" : isSelected?.status === "rejected" ? "bg-red-100" : "bg-[#ffffea]"}`}>
                    {isSelected?.status === "approved" ? <Completed /> : isSelected?.status === "rejected" ? <Reject /> : <Pending />}
                    <div className={`text-center ${isSelected?.status === "approved" ? "text-[#1f7634]" : isSelected?.status === "rejected" ? "text-red-500" : "text-[#bb6902]"} text-[14px] font-medium leading-[18px]`}>{isSelected?.status}</div>
                  </div>
                </div>
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Registered Date: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.date_created}</span>
                </h4>
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Registered Approved: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.date_approved}</span>
                </h4>
                {/* <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Approver: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.approved_by}</span>
                </h4> */}
              </div>
            </div>
          )}
          {user === "participants" && (
            <div className="flex-col w-full justify-start items-start gap-[24px] inline-flex">
              <div className="self-stretch flex-col justify-center items-center gap-2 flex">
                <h4 className="text-[#3c3b3e] text-2xl font-semibold font-openSans">
                  {isSelected?.first_name} {isSelected?.last_name}
                </h4>
              </div>

              <div className="w-full h-px bg-[#e6e6e7]" />
              <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Email: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.email}</span>
                </h4>
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Phone Number: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.phone_number}</span>
                </h4>
              </div>
              <div className="w-full h-px bg-[#e6e6e7]" />
              <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Master Class: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.master_class}</span>
                </h4>
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Selected Master Class: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.selected_master_class}</span>
                </h4>
              </div>
              <div className="w-full h-px bg-[#e6e6e7]" />
              <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Attendance: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.attendance_count}</span>
                </h4>
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Date Registered: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.date_created}</span>
                </h4>
                <h4 className="text-[#6b6a70] w-full text-[14px] font-semibold font-openSans">
                  Date Signed In: <span className="text-[#86858b] text-[14px] font-normal font-openSans">{isSelected?.date_sign_in}</span>
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QrCodeDetails;
