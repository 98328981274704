// import { Link } from "react-router-dom";
import spiral from "../../../Images/svg/spiral.svg";
import latest1 from "../../../Images/photos/news1.png";
import latest2 from "../../../Images/photos/news2.png";
import latest3 from "../../../Images/photos/news3.png";
import latest4 from "../../../Images/photos/news4.png";
import arrow from "../../../Images/svg/arrowRight.svg";
import parent from "../../../Images/photos/par.png";
import cancel from "../../../Images/svg/Cancel.svg";
// import tick from "../../../Images/svg/ticked.svg";
import { useState } from "react";

function LatestNews() {
  const [join, setJoin] = useState(false);
  const box = [
    {
      id: 1,
      picture: latest1,
      bgColor: "#FFB472",
      title: "10 The Benefits of Positive Parenting",
      description: "Discover how positive parenting techniques can enhance your child's development and create a harmonious family environment.",
    },
    {
      id: 2,
      picture: latest2,
      bgColor: "#F3AED0",
      title: "Top 10 Activities to Boost Creativity in Kids",
      description: "Engage your kids in these creative activities to boost their imagination and cognitive skills. Learn more about each activity here.",
    },
    {
      id: 3,
      picture: latest3,
      bgColor: "#79DCFF",
      title: "Healthy Eating Habits for the Whole Family",
      description: "Learn how to establish healthy eating habits that benefit the entire family, and get tips on nutritious meals and snacks.",
    },
    {
      id: 4,
      picture: latest4,
      bgColor: "#FCCA43",
      title: "Dealing with Tantrums: A Parent's Guide",
      description: "Understand why tantrums happen and how to effectively manage them with our comprehensive guide for parents.",
    },
  ];

  return (
    <>
      <div className=" mt-[32px] w-full h-fit gap-[60px] bg-[#F5F5F6] py-[96px] 100:px-[48px] sm:px-[64px] flex-col flex justify-center items-center">
        <div className="inline-flex w-full flex-col   justify-center items-center ">
          <div className=" flex 100:w-full  lg:w-[740px] justify-between items-center ">
            <div className=" 100:w-[70px] md:w-[100px]  ">
              <img src={spiral} alt="spiral" />
            </div>
            <div className=" 100:w-[70px] md:w-[100px] ">
              <img className="transform scale-x-[-1]" src={spiral} alt="spiral" />
            </div>
          </div>
          <div className=" flex-col self-stretch justify-center md:mt-[-16px] text-center items-center gap-[10px] inline-flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Latest News in Parenting</h3>
            <p className=" text-center text-[#6b6a70] text-lg font-normal font-openSans">Stay Updated with the Newest Trends and Information</p>
          </div>
        </div>
        <div className=" flex h-fit xl:flex-nowrap  100:flex-wrap  justify-center items-center w-full lg:gap-8 100:gap-10 ">
          {box.map((item) => (
            <div key={item.id} style={{ background: item.bgColor }} className={`100:w-full   sm:w-full md:w-[45%]  lg:w-[314px] 100:h-[400px] md:h-[70vh] lg:h-[400px] rounded-[24px] flex flex-col items-start justify-start `}>
              <div
                className="rounded-[24px] flex justify-end  items-end w-full h-full "
                style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}
              >
                <div className="h-fit w-full rounded-b-[24px] px-5 py-6 bg-black/30 border border-white/50 backdrop-blur-xl flex-col justify-start items-start gap-[16px] inline-flex">
                  <div className=" flex flex-col gap-[4px] w-full  ">
                    <h4 className="self-stretch text-white text-base font-bold font-openSans ">{item.title}</h4>
                    <p className="self-stretch text-[#cfcfd2] text-sm font-normal ">{item.description}</p>
                  </div>
                  <div onClick={() => setJoin(true)} className=" cursor-pointer justify-start items-center gap-2.5 inline-flex">
                    <h5 className="text-[#e6e6e7] text-base font-bold font-playfair underline">Read More</h5>
                    <img src={arrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {join && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black/60 top-0 left-0">
            <div className={`100:w-[85%] sm:w-[500px] p-2.5  h-[60vh] gap-2  bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex`}>
              <div style={{ backgroundImage: `url(${parent})`, backgroundSize: "cover" }} className=" p-[6px] w-full h-[266.67px] rounded-[20px]">
                <div className=" flex justify-end items-end w-full">
                  <div onClick={() => setJoin(false)} className="w-12 cursor-pointer h-12  justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                    <img className=" " src={cancel} alt="Cancel" />
                  </div>
                </div>
              </div>
              <div className="h-full w-full overflow-y-scroll p-6 bg-[#f0faff] rounded-[20px] flex-col justify-start items-start gap-[32px] inline-flex">
                <div className="h-fit w-full flex-col justify-start items-start gap-4 inline-flex">
                  <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                    <h2 className="text-[#3c3b3e] text-2xl font-semibold font-openSans">10 The Benefits of Positive Parenting</h2>
                    <p className="self-stretch text-[#86858b] text-sm font-normal leading-[22px] font-openSans">
                      A supportive community for single parents to share their unique experiences, offer advice, and find resources. This group provides a space to connect with others who understand the challenges and rewards of single parenting.{" "}
                    </p>
                  </div>
                  <div className="h-px w-full bg-[#e6e6e7]" />
                  <div className="h-fit w-full flex-col justify-start items-start gap-3.5 inline-flex">
                    <h3 className="text-[#4e4d51] text-lg font-semibold font-openSans">1. Builds Strong Emotional Bonds</h3>
                    <p className="self-stretch text-[#86858b] text-[12px] font-normal leading-[22px] font-openSans">
                      Positive parenting helps to build a strong, loving bond between parents and children. By consistently showing affection, listening, and spending quality time together, children feel valued and secure, fostering trust and
                      emotional intimacy.{" "}
                    </p>
                  </div>
                  <div className="h-px w-full bg-[#e6e6e7]" />
                  <div className="h-fit w-full flex-col justify-start items-start gap-3.5 inline-flex">
                    <h3 className="text-[#4e4d51] text-lg font-semibold font-openSans">2. Promotes Healthy Self-Esteem</h3>
                    <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                      <p className="self-stretch text-[#86858b] text-[12px] font-normal leading-[22px] font-openSans">
                        Encouragement and positive reinforcement help children develop a healthy self-esteem. Positive parenting acknowledges efforts and achievements, making children feel competent and proud of their accomplishments.
                      </p>
                      <div className="text-[#86858b] text-xs font-normal font-openSans leading-[18px]">
                        <span className="text-[#6b6a70] font-semibold ">Example: </span>Praising a child’s effort in completing a task, rather than just the outcome, builds confidence and motivation.
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LatestNews;
