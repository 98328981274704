import "./App.css";
// import Homepage from "./Components/Landing Page/Homepage";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";

function App() {
  return (
    <>
      <RouterProvider router={router} />
      {/* <Homepage /> */}
    </>
  );
}

export default App;
