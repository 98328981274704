import { useState } from "react";
import Header from "../Header";
import Footer from "./NavbarComponents/Footer";
import NewsLetter from "./NavbarComponents/NewsLetter";
import cancel from "../../Images/svg/Cancel.svg";
import tick from "../../Images/svg/ticked.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

function ContactUs() {
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone_number: Yup.string().matches(/^\d+$/, "Phone number must contain only digits").required("Phone number is required"),
    datecreated: Yup.string().required("Date is required"),
    message_info: Yup.string().required("Message is required"),
    // vendor_category: Yup.object().required("Please select a category option"),
  });
  const [save, setSave] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      datecreated: "",
      message_info: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_number: values.phone_number,
        datecreated: values.datecreated,
        message_info: values.message_info,
      };
      console.log(formData);
      try {
        const response = await axios.post("https://www.thebabyconvention.com/wbe/api/add_contact.php", formData);
        console.log(response);
        if (response.data.responsecode === "200") {
          setSave(true);
          setResponseMessage(response.data.responsemessage);
        } else {
          setResponseMessage("Registration failed. Please try again.");
        }
        return response.data;
      } catch (error) {
        console.error("Error registering user:", error);
        setResponseMessage("An error occurred. Please try again later.");
      }
    },
  });
  return (
    <>
      <Header />
      <div className="w-full h-fit gap-[48px]  pt-[32px] 100:px-[32px] lg:px-[64px] flex-col flex justify-center items-center">
        <div className=" flex-col  100:w-[90%] justify-center md:w-[758px] text-center items-center gap-[10px] inline-flex">
          <h3 className="text-[#3c3b3e] 100:text-[40px] md:text-[64px] font-semibold font-playfair">Chat to our friendly team</h3>
          <p className=" text-center 100:w-full md:w-[500px] text-[#6b6a70] text-lg font-normal font-openSans">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
        </div>
        <div className=" xl:w-[1200px] 100:flex-col lg:flex-row flex justify-center items-start w-full gap-[32px] md:px-[56px]">
          <div className=" h-full 100:h-full 100:w-full flex lg:w-[311px] 100:flex-col md:flex-row   lg:flex-col gap-[32px] items-start justify-start">
            <div className="h-[276px] w-full  p-6 bg-[#f5f5f6] rounded-2xl flex-col justify-start items-start gap-16 inline-flex">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebd4] rounded-[28px] border-8 border-[#fff6ed]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6" stroke="#FE8734" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className="flex flex-col justify-start items-start gap-[20px]">
                <div className="flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#3c3b3e] text-xl font-medium font-playfair">Email</div>
                  <div className="self-stretch text-[#6b6a70] text-base font-normal font-openSans">Speak to our friendly team.</div>
                </div>
                <a href="mailto:support@thebabyconvention.com" className="  100:truncate text-[#fe8734] text-base font-semibold font-openSans">
                  support@thebabyconvention.com
                </a>
              </div>
            </div>
            <div className="h-fit w-full  p-6 bg-[#f5f5f6] rounded-2xl flex-col justify-start items-start gap-16 inline-flex">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebd4] rounded-[28px] border-8 border-[#fff6ed]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                    stroke="#FE8734"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="flex flex-col justify-start items-start gap-[20px]">
                <div className="flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#3c3b3e] text-xl font-medium font-playfair">Phone</div>
                  <div className="self-stretch text-[#6b6a70] text-base font-normal font-openSans">Mon-Fri from 8am to 5pm.</div>
                </div>
                <div className="flex-col justify-start items-start gap-2 inline-flex">
                  <a href="tel:+2348103076303" className=" text-[#fe8734] text-base font-semibold font-openSans">
                    <span className="text-[#6b6a70]  font-normal"> Philip: </span> 08103076303
                  </a>
                  <a href="tel:+2349033720287" className=" text-[#fe8734] text-base font-semibold font-openSans">
                    <span className="text-[#6b6a70]  font-normal"> Deola: </span> 09033720287
                  </a>
                  <a href="tel:+2348177108267" className=" text-[#fe8734] text-base font-semibold font-openSans">
                    <span className="text-[#6b6a70]  font-normal"> Victor: </span> 08177108267
                  </a>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className=" h-fit  w-full md:px-8 py-10 bg-[#f5f5f6] rounded-2xl flex-col justify-center items-center gap-16 inline-flex">
            <div className="100:w-full sm:w-[480px] px-6 flex-col justify-center items-center gap-8 inline-flex">
              <form onSubmit={formik.handleSubmit} className=" 100:w-full sm:w-[480px]  pb-[30px] rounded-[20px] flex-col justify-start items-start gap-4 inline-flex">
                {/* <div className=" w-full flex justify-center gap-[32px]">
                  <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="First name">
                    First name
                    <input type="name" placeholder="First name" className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center" />
                  </label>
                  <label className="flex-col w-full  justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Last name">
                    Last name
                    <input type="name" placeholder="Last name" className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center" />
                  </label>
                </div> */}
                <div className="100:flex-col phone:flex-row w-full flex justify-center gap-[32px]">
                  <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="First name">
                    First name
                    <input
                      type="name"
                      placeholder="First name"
                      className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                      id="first_name"
                      {...formik.getFieldProps("first_name")}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.first_name}</div> : null}
                  </label>
                  <label className="flex-col w-full  justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Last name">
                    Last name
                    <input
                      type="name"
                      placeholder="Last name"
                      className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                      id="last_name"
                      {...formik.getFieldProps("last_name")}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.last_name}</div> : null}
                  </label>
                </div>
                <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                  Email
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                    id="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.email}</div> : null}
                </label>
                <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Phone number">
                  Phone number
                  <input
                    type="tel"
                    placeholder="Phone number"
                    className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                    id="phone_number"
                    {...formik.getFieldProps("phone_number")}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.phone_number}</div> : null}
                </label>
                <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                  Date Created
                  <input
                    type="date"
                    placeholder="Date Created"
                    className="outline-[#fe8734]  h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                    id="datecreated"
                    {...formik.getFieldProps("datecreated")}
                  />
                  {formik.touched.datecreated && formik.errors.datecreated ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.datecreated}</div> : null}
                </label>
                <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Message">
                  Message
                  <textarea placeholder="Your message" className="outline-[#fe8734] h-[129px] w-full p-4 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center" id="message_info" {...formik.getFieldProps("message_info")} />
                  {formik.touched.message_info && formik.errors.message_info ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.message_info}</div> : null}
                </label>
                <label className="w-full justify-start items-start gap-[12px] inline-flex text-[#86858b] text-base font-normal font-openSans" htmlFor="Message">
                  <input style={{ borderRadius: "6px", border: "1px", borderColor: "#aeaeb2" }} className="bg-white  rounded-[6px] h-[20px] w-[20px] appearance-none checked:bg-[#fe8734] outline-[#fe8734]  border border-[#aeaeb2]" type="checkbox" />
                  You agree to our friendly privacy policy.
                </label>
                <button type="submit" className="w-full mt-[32px] ">
                  <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">{isLoading ? "LOADING..." : " Send Message"}</div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
      {save && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="w-full px-2 justify-end items-center inline-flex">
                <div onClick={() => setSave(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>

              <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
                <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                  <img src={tick} alt="tick" />
                </div>
                <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                  <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Successful</h3>
                  <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{responseMessage}</p>
                </div>
                <div className=" w-full flex justify-center items-center">
                  <button>
                    <div onClick={() => setSave(false)} className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">
                      Ok
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ContactUs;
