import SideBar from "./SideBar";
import user from "../Images/photos/userID.png";
import { useEffect, useState } from "react";
import axios from "axios";

function Profile() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state to track async operations
  const [error, setError] = useState(null); // Error state to handle any login issues

  useEffect(() => {
    const storedUserData = localStorage.getItem("babyConv");

    if (storedUserData) {
      const parsedData = JSON.parse(storedUserData);
      setData(parsedData); // Set user data in state
    } else {
      setError("No user data found. Please login again.");
    }

    setLoading(false); // Stop loading once data is retrieved
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const dataSend = {
  //       username: "adekunle.renner@hotmail.com",
  //       password: "IWNXg27p8v",
  //     };

  //     try {
  //       const res = await axios.post("https://thebabyconvention.com/wbe/api/login.php", dataSend, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       console.log("Login response: ", res);

  //       // Assuming that user details are part of res.data.user
  //       if (res.data && res.data.data) {
  //         setData(res.data.data); // Set user data in state
  //       } else {
  //         throw new Error("User details not found in response");
  //       }
  //     } catch (error) {
  //       console.error("Login error:", error);
  //       setError("Unable to fetch user details. Please try again.");
  //     } finally {
  //       setLoading(false); // Stop loading spinner after fetching data
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <>
      <div className="bg-[#f5f5f6] h-screen w-full flex justify-between items-start">
        <SideBar />
        <div className=" flex 100:justify-center items-center lg:justify-start flex-col pb-[80px]   w-full lg:pl-[250px]">
          <div className="bg-gradient-to-r w-full 100:h-[20vh] md:h-[30vh] from-[#fe8734]/40  via-[#41CFFE]/70    to-[#fe8734]/40"></div>
          <div className="flex gap-[32px] mt-[-70px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
            <div className=" w-full px-8 flex-col justify-center items-center gap-6 inline-flex">
              <div className={`w-40 h-40 rounded-[200px] shadow border-4  border-[#E8F7FF] `}>
                <img src={user} alt={data?.name} className=" rounded-full w-40 h-40 object-cover" />
              </div>
              <div className="w-full flex-col justify-start items-center gap-1 flex">
                <h2 className="self-stretch text-center text-[#3a3c3f] text-[28px] font-medium  leading-[38px]">{data?.name}</h2>
                <h5 className="self-stretch text-center text-[#4a4f54] text-sm font-medium  leading-normal">{data?.email}</h5>
              </div>
              <form className="flex-col 100:w-full lg:w-[800px] flex gap-[24px] justify-start items-start ">
                <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                  <h3 className="text-[#4e4d51] text-sm font-semibold font-openSans">Name</h3>
                  <div className="w-full h-[43px]  pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center gap-2 inline-flex  text-[#4e4d51] text-sm font-semibold font-openSans">{data?.name}</div>
                </div>{" "}
                <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                  <h3 className="text-[#4e4d51] text-sm font-semibold font-openSans">Email</h3>
                  <div className="w-full h-[43px]  pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center gap-2 inline-flex  text-[#4e4d51] text-sm font-semibold font-openSans">{data?.email}</div>
                </div>{" "}
              </form>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default Profile;
