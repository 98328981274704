import user1 from "../../../Images/photos/community1.png";
import user2 from "../../../Images/photos/community2.png";
import user3 from "../../../Images/photos/community3.png";
import user4 from "../../../Images/photos/community4.png";
import user5 from "../../../Images/photos/community5.png";
import user6 from "../../../Images/photos/img1.png";

function Stories() {
  const testimony = [
    {
      id: 1,
      picture: user1,
      name: "Sarah T.",
      story: "This website has been a lifesaver! The advice and product recommendations have made my parenting journey so much easier.",
    },
    {
      id: 2,
      picture: user2,
      name: "John D",
      story: "The expert tips on newborn care helped us get through those first few months with confidence. Highly recommend!",
    },
    {
      id: 3,
      picture: user3,
      name: "Dr. Amanda K",
      story: "The resources provided here are top-notch. I often refer my patients to this site for trustworthy advice and recommendations.",
    },
    {
      id: 4,
      picture: user4,
      name: "Jessica M.",
      story: "As a mom and blogger, I love sharing this site with my followers. It's a fantastic resource for new and seasoned parents alike.",
    },
    {
      id: 5,
      picture: user5,
      name: "Emily R.",
      story: "I found the perfect baby products thanks to the featured section. It's like having a personal shopping assistant!",
    },
    {
      id: 6,
      picture: user6,
      name: "John Jay.",
      story: "I got perfect baby products thanks to the featured section. It's like having a personal shopping assistant!",
    },
  ];
  return (
    <>
      <div className="w-full h-fit gap-[60px]  py-[96px] 100:px-[32px] lg:px-[64px] flex-col flex justify-center items-center">
        <div className=" flex-col self-stretch justify-center md:mt-[-16px] text-center items-center gap-[10px] inline-flex">
          <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">What Our Community Says</h3>
          <p className=" text-center text-[#6b6a70] text-lg font-normal font-openSans">Hear from Our Happy Parents and Experts</p>
        </div>
        <div className="xl:w-[1312px] 100:w-full flex-wrap h-fit justify-center items-center gap-8 flex">
          {testimony.map((item) => (
            <div key={item.id} className="xl:w-[45%] lg:w-[80%] 100:w-full 100:h-fit lg:h-fit xl:h-[239px] px-6 py-10 bg-[#f0faff] rounded-[20px] flex-col justify-center items-center gap-4 inline-flex">
              <div>
                {" "}
                <img className=" w-[70px] rounded-full" src={item.picture} alt={item.name} />
              </div>
              <div className=" w-fit flex-col justify-center items-center gap-2 inline-flex">
                <h4 className="grow shrink basis-0 text-center text-[#3c3b3e] text-lg font-semibold font-openSans">{item.name}</h4>
                <p className="md:w-[484px] 100:w-[90%] italic text-center text-[#6b6a70] text-[15px] font-playfair font-normal">{item.story}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Stories;
