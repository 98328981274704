import { Link } from "react-router-dom";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import { useEffect, useState } from "react";
import { ReactComponent as Reject } from "../Images/svg/reject.svg";
import axios from "axios";

function Error() {
  //   const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const transactionId = urlParams.get("reference");

      // Retrieve paymentData from localStorage
      const storedPaymentData = JSON.parse(localStorage.getItem("paymentData"));

      if (transactionId && storedPaymentData) {
        try {
          const response = await axios.post("https://thebabyconvention.com/wbe/api/payments.php", {
            action: 2,
            id: storedPaymentData.id, // Use the stored ID from paymentData
            transaction: transactionId,
            vendor_name: "", // You can add this if necessary
            email: storedPaymentData.email, // Use the stored email from paymentData
          });

          if (response.data.status === "success") {
            setResponseMessage("Payment successful!");
          } else {
            setResponseMessage("Payment failed or rejected. Please try again.");
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
          setResponseMessage("An error occurred while verifying the payment.");
        }
      } else {
        setResponseMessage("Payment data is missing or invalid.");
      }
    };

    checkPaymentStatus();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center px-[10px] py-[40px]">
        <div className="h-[100px] bg-white/40 w-full top-0 left-[40px] 100:pr-[70px] right-[40px] flex fixed justify-between items-center">
          <Link onClick={() => window.scrollTo(0, 0)} to="/home">
            <div className="w-fit flex justify-center items-center gap-2">
              <div>
                <img className="w-full" src={arrow} alt="home" />
              </div>
              <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
            </div>
          </Link>
          <div className="100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className="w-[120px] 100:hidden md:block"></div>
        </div>
        <div className="h-fit 100:w-[85%]  sm:w-[498px] mt-[130px] p-6 bg-[#fff6ed] rounded-3xl shadow flex-col justify-start items-start gap-2.5 inline-flex">
          <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
            <div className="w-12 h-12 flex justify-center items-center bg-red-300 rounded-[28px] border-8 border-red-100">
              <Reject />
            </div>
            <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
              <h3 className="text-[#3c3b3e] text-center text-xl font-semibold font-openSans">{responseMessage}</h3>
              <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{responseMessage}</p>
            </div>
            <div className=" w-full flex justify-center items-center">
              <button>
                <div
                  onClick={() => {
                    // setResponseMessage(false);
                    // setLoading(false);
                  }}
                  className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"
                >
                  Ok
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
