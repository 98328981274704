import { createBrowserRouter } from "react-router-dom";
// import Homepage from "../Components/Landing Page/Homepage";
import Home from "../Components/Landing Page/Navbar/Home";
import Products from "../Components/Landing Page/Navbar/Products";
import ExpertAdvice from "../Components/Landing Page/Navbar/ExpertAdvice";
import Community from "../Components/Landing Page/Navbar/Community";
import ContactUs from "../Components/Landing Page/Navbar/ContactUs";
import Events from "../Components/Landing Page/Navbar/Events";
import Options from "../Components/Landing Page/Options";
import Participant from "../Components/Forms/Participant";
import Vendor from "../Components/Forms/Vendor";
import Sponsor from "../Components/Forms/Sponsor";
import VendorInstruction from "../Components/Forms/VendorInstruction";
// import VendorBooth from "../Components/Forms/Payment";
import Dashboard from "../Components/Admin/Dashboard";
import UserManagement from "../Components/Admin/UserManagement";
import VendorAdmin from "../Components/Admin/Vendor";
import Login from "../Components/Forms/Login";
import Reciept from "../Components/Forms/Reciept";
import Sponsors from "../Components/Admin/Sponsors";
import Profile from "../Components/Admin/Profile";
import Successful from "../Components/Forms/Successful";
import Payment from "../Components/Forms/Payment";
import QrCodeDetails from "../Components/Admin/QR/QrCodeDetails";
import Success from "../Components/StatusMessage/Success";
import Error from "../Components/StatusMessage/Error";
// import Test from "../Components/Forms/Test";

export const router = createBrowserRouter([
  // AUTH ROUTE
  {
    path: "auth",
    children: [
      // { path: "login", element: <Login /> },
      // { path: "register", element: <Register /> },
      // { path: "forgot-password", element: <ForgotPassword /> },
      // { path: "otp:id/:token", element: <Otp /> },
      // { path: "otpforgotpassword:id/:token", element: <OtpForgotpassword /> },
      // { path: "create-password:id/:token", element: <CreatePassword /> },
      // { path: "signup", element: <SignUp /> },
    ],
  },
  //   HOME ROUTE
  {
    path: "",
    children: [{ path: "/", element: <Home /> }],
  },

  // NAVIGATION
  {
    path: "",
    children: [
      { path: "home", element: <Home /> },
      { path: "products", element: <Products /> },
      { path: "expert-advice", element: <ExpertAdvice /> },
      { path: "community", element: <Community /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "events", element: <Events /> },
      { path: "options", element: <Options /> },
    ],
  },

  // FORM
  {
    path: "",
    children: [
      { path: "instruction", element: <VendorInstruction /> },
      { path: "participant", element: <Participant /> },
      // { path: "test", element: <Test /> },
      { path: "vendor", element: <Vendor /> },
      { path: "payment/:email", element: <Payment /> },
      { path: "sponsor", element: <Sponsor /> },
      { path: "login", element: <Login /> },
      { path: "successful", element: <Successful /> },
    ],
  },
  // Admin
  {
    path: "admin",
    children: [
      { path: "dashboard", element: <Dashboard /> },
      { path: "user-management", element: <UserManagement /> },
      { path: "vendor", element: <VendorAdmin /> },
      { path: "sponsor", element: <Sponsors /> },
      { path: "reciept", element: <Reciept /> },
      { path: "profile", element: <Profile /> },
      { path: "qr-code-details/:id/:user/:booth?", element: <QrCodeDetails /> },
      // qr-code-details/:userId
    ],
  },
  {
    path: "status",
    children: [
      { path: "success", element: <Success /> },
      { path: "error", element: <Error /> },
    ],
  },
]);
