import cancel from "./Images/svg/Cancel.svg";

function PrivacyVendor({ setPrivacy }) {
  return (
    <>
      <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
        <div className={`100:w-[85%] sm:w-[500px]  h-[619px] pb-[40px] p-2.5 gap-[16px] bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex`}>
          <div className=" pl-[24px] flex justify-between items-center w-full">
            <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">{/* */}</h3>

            <div onClick={() => setPrivacy(false)} className="w-12 cursor-pointer h-12  justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </div>
          <div className=" flex-col flex justify-start gap-[24px] items-center w-full h-full overflow-y-scroll">
            <div className=" w-full flex justify-center items-center text-center">
              <p className=" w-[90%]   text-[#6b6a70] text-base font-normal font-openSans">
                Dear Vendor, We’re excited to have you to join us as a vendor at The Baby Convention! Showcase your products or services to a targeted audience of expectant parents and families.
              </p>
            </div>
            <div className=" px-[44px] flex flex-col gap-[24px] justify-start items-start w-full">
              <h4 className=" text-[#6b6a70] text-base font-semibold font-openSans">Here’s how to get started:</h4>
              <ul className=" w-full list-decimal flex flex-col gap-[24px]  text-left text-[#6b6a70] text-base font-normal font-openSans">
                <li>
                  <b>Complete the Vendor Application:</b> Fill out our online application form, providing details about your business and the products/services you offer.
                </li>
                <li>
                  {" "}
                  <b>Review and Approval:</b> Our team will review your application and contact you within 24 hours to confirm your participation.
                </li>
                <li>
                  <b>Booth Selection and Payment:</b> If selected, we'll provide you with information about available booth sizes, where they will be located, and their prices. Choose your preferred option and make the required payment.
                </li>
                <li>
                  <b>Confirmation and Logistics:</b> Once payment is received, we'll confirm your booth has been secured and provide you with details for setup.
                </li>
              </ul>{" "}
              <h4 className=" text-[#6b6a70] text-base font-semibold font-openSans">Booth Prices and Locations:</h4>
              <ul className=" w-full list-decimal flex flex-col gap-[24px] text-left text-[#6b6a70] text-base font-normal font-openSans">
                <li>
                  Early Bird (Sept 1st - Oct 15th):
                  <ul className=" list-disc">
                    <li className="my-[8px]">9 sqm inside: 300k</li>
                    <li className="mb-[4px]">4 sqm inside: 250k</li>
                    <li className="mb-[4px]">All booths outside: 250k</li>
                  </ul>
                </li>
                <li>
                  Normal Fee (Oct 16th - Nov 1st):
                  <ul className=" list-disc">
                    <li className="my-[8px]">9 sqm inside: 350k</li>
                    <li className="mb-[4px]">4 sqm inside: 300k</li>
                    <li className="mb-[4px]">All booths outside: 300k</li>
                  </ul>
                </li>
                <p>
                  <span className=" text-[#626166] font-medium">Please Note</span> This expo is currently open to exhibitors in Lagos only.
                </p>
              </ul>
              <h4 className=" text-[#6b6a70] text-base font-semibold font-openSans">Terms and Conditions:</h4>
              <ul className=" w-full list-disc flex flex-col gap-[24px]  text-left text-[#6b6a70] text-base font-normal font-openSans">
                <li>
                  <b>Compliance:</b> The exhibitor must adhere to all laws, regulations, and guidelines set by the organizers and venue.
                </li>
                <li>
                  <b>Booth Staffing:</b> The exhibitor must ensure that their booth is staffed and operational during expo hours.{" "}
                </li>
                <li>
                  <b>Setup and Breakdown:</b> The exhibitor must handle setup, maintenance, and dismantling of their exhibit.{" "}
                </li>
                <li>
                  <b>Payment:</b> The exhibitor must make full payment before the specified deadline.{" "}
                </li>
                <li>
                  <b>Cancellation:</b> All cancellations must be made in writing before October 16th. Refunds will be provided, subject to certain conditions.
                </li>
                <li>
                  <b>Insurance:</b> Exhibitors are required to have apropriate insurance coverage.
                </li>
                <li>
                  <b>Liability:</b> The organizers are not liable for any losses or damages incurred by exhibitors or their representatives.
                </li>
                <li>
                  <b>Conduct:</b> Exhibitors must to conduct themselves professionally and not engage in any activity that disrupts the expo.
                </li>
                <li>
                  <b>Intellectual Property:</b> The exhibitor must respect all intellectual property rights and not display or distribute any unauthorized materials.
                </li>
                <li>
                  <b>Health and Safety:</b> The exhibitor must adhere to all health and safety regulations.
                </li>
                <li>
                  <b>Product Safety:</b> The exhibitor must ensure all products comply with safety standards.
                </li>
                <li>
                  <b>Force Majeure:</b> The organizers are not liable for cancellations or disruption of event due to unforeseen circumstances.
                </li>
                <p className="text-[#6d6d70] text-base font-normal font-openSans">By proceeding, you acknowledge and agree to these terms and conditions.</p>
                <p className="text-[#6d6d70] text-base font-normal font-openSans">We look forward to welcoming you to The Baby Convention!</p>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyVendor;
