import { useEffect, useState } from "react";
import Header from "../Header";
import resource1 from "../../Images/photos/resources1.png";
import resource2 from "../../Images/photos/resources2.png";
import resource3 from "../../Images/photos/resources3.png";
import up from "../../Images/svg/arrowUp.svg";
import Footer from "./NavbarComponents/Footer";
import NewsLetter from "./NavbarComponents/NewsLetter";

function ExpertAdvice() {
  const [showButton, setShowButton] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [readMore2, setReadMore2] = useState(false);
  const [readMore3, setReadMore3] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  return (
    <>
      <Header />
      <div className="w-full h-fit gap-[36px]  py-[64px] 100:px-[28px] lg:px-[64px] flex-col flex justify-center items-center">
        <div className=" flex-col self-stretch justify-center md:mt-[-16px] text-center items-center gap-[10px] inline-flex">
          <h3 className="text-[#3c3b3e] text-[32px] font-bold font-playfair">Expert Advice and Resources</h3>
          <p className=" text-center text-[#6b6a70] text-lg font-normal font-openSans">Get Tips and Guidance from Parenting Experts</p>
        </div>
        <div className="lg:w-[1000px] 100:w-full h-fit  p-2.5 flex-col justify-center items-center inline-flex">
          <div className=" w-full">
            <img className="w-full" src={resource1} alt="" />
          </div>
          <div className=" 100:w-[90%] mt-[-56px] z-20 lg:w-[950px] h-fit px-8 py-6 bg-[#f0faff] rounded-[20px] flex-col justify-center items-center gap-4 inline-flex">
            <div className=" w-full flex justify-end items-end">
              <h3 className="text-[#86858b] text-sm font-semibold font-openSans">June 25, 2024 </h3>
            </div>
            <div className=" w-full flex-col justify-center items-center gap-2.5 flex">
              <section className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <h2 className="text-left text-[#3c3b3e] text-[32px] font-semibold font-openSans">Top 10 Pregnancy Myths Debunked</h2>
                <p className=" 100:w-full lg:w-[664px] text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  Explore common pregnancy myths and the facts behind them. This article covers everything from old wives' tales to modern misconceptions, providing evidence-based information to ease your mind during pregnancy.
                </p>
              </section>
            </div>
            <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
              <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <ol className=" flex-col  justify-start items-start gap-[48px] flex list-decimal text-[#3c3b3e] text-xl font-semibold font-openSans">
                  <li className="  ">
                    Myth: You Can't Exercise During Pregnancy
                    <p className="self-stretch text-[#6b6a70] mt-[10px] text-base font-normal font-openSans leading-[25px]">
                      <b>Fact:</b> Exercise is not only safe for most pregnant women but also highly recommended. Regular physical activity can help manage weight, reduce back pain, improve mood, and prepare the body for labor. Always consult with
                      your healthcare provider before starting any exercise regimen.
                    </p>
                  </li>
                  <li>
                    Myth: You Must Eat for Two
                    <p className="self-stretch mt-[10px] text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      <b>Fact:</b> While it's true that you need extra nutrients, the idea of eating for two is misleading. Most women only need about 300 extra calories per day in the second and third trimesters. Focus on nutrient-dense foods to
                      meet your needs.
                    </p>
                  </li>
                  <li>
                    Myth: Morning Sickness Only Happens in the Morning
                    <p className="self-stretch mt-[10px] text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      <b>Fact:</b> Morning sickness can occur at any time of the day or night. It typically begins around the 6th week of pregnancy and can last until the 12th week, though some women may experience it throughout their pregnancy.{" "}
                      {readMore ? (
                        <></>
                      ) : (
                        <span className="text-[#fe8734] cursor-pointer font-semibold underline" onClick={() => setReadMore(true)}>
                          Read more
                        </span>
                      )}
                    </p>
                  </li>
                  {readMore && (
                    <>
                      <li>
                        Myth: You Should Avoid All Fish
                        <p className="self-stretch text-[#6b6a70] mt-[10px] text-base font-normal font-openSans leading-[25px]">
                          <b>Fact:</b> Not all fish are off-limits. Certain types of fish, like salmon and sardines, are high in omega-3 fatty acids, which are beneficial for both you and your baby. Avoid fish high in mercury, such as shark,
                          swordfish, and king mackerel.
                        </p>
                      </li>
                      <li>
                        Myth: You Can't Dye Your Hair
                        <p className="self-stretch text-[#6b6a70] mt-[10px] text-base font-normal font-openSans leading-[25px]">
                          <b>Fact:</b> While it's best to avoid frequent exposure to hair dye, occasional use of semi-permanent and permanent dyes is generally considered safe. To minimize risk, use natural or plant-based dyes and apply them in a
                          well-ventilated area.
                        </p>
                      </li>
                      <li>
                        Myth: You Shouldn't Take Baths
                        <p className="self-stretch text-[#6b6a70] mt-[10px] text-base font-normal font-openSans leading-[25px]">
                          <b>Fact:</b>Warm baths are safe and can help soothe sore muscles and improve circulation. However, avoid hot tubs and very hot baths, as they can raise your body temperature to unsafe levels for your baby.
                        </p>
                      </li>
                      <li>
                        Myth: Spicy Food Can Induce Labor
                        <p className="self-stretch text-[#6b6a70] mt-[10px] text-base font-normal font-openSans leading-[25px]">
                          <b>Fact:</b> There is no scientific evidence to support that spicy foods can induce labor. While it may cause heartburn or discomfort, it won't trigger labor.{" "}
                        </p>
                      </li>
                      <li>
                        Myth: You Can't Drink Coffee
                        <p className="self-stretch text-[#6b6a70] mt-[10px] text-base font-normal font-openSans leading-[25px]">
                          <b>Fact:</b> Moderate caffeine intake is generally safe during pregnancy. Limit your caffeine consumption to about 200 mg per day, which is roughly one 12-ounce cup of coffee.{" "}
                        </p>
                      </li>

                      <li>
                        Myth: Pregnancy Brain Is Just an Excuse
                        <p className="self-stretch text-[#6b6a70] mt-[10px] text-base font-normal font-openSans leading-[25px]">
                          <b>Fact:</b> "Pregnancy brain" or "momnesia" refers to the forgetfulness and cognitive changes some women experience during pregnancy. Hormonal changes, stress, and sleep deprivation can contribute to these symptoms.{" "}
                        </p>
                      </li>
                      <li>
                        Myth: You Can't Fly While Pregnant
                        <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                          <b>Fact:</b> Air travel is generally safe for pregnant women up to 36 weeks of gestation. Always consult with your healthcare provider before flying, and take precautions such as walking periodically and staying hydrated.{" "}
                          <span className="text-[#fe8734] cursor-pointer font-semibold underline" onClick={() => setReadMore(false)}>
                            Show Less
                          </span>
                        </p>
                      </li>
                    </>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[1000px] 100:w-full h-fit  p-2.5 flex-col justify-center items-center inline-flex">
          <div className=" w-full">
            <img className="w-full" src={resource2} alt="" />
          </div>
          <div className=" 100:w-[90%] mt-[-56px] z-20 lg:w-[950px] h-fit px-8 py-6 bg-[#f0faff] rounded-[20px] flex-col justify-center items-center gap-4 inline-flex">
            <div className=" w-full flex justify-end items-end">
              <h3 className="text-[#86858b] text-sm font-semibold font-openSans">June 25, 2024 </h3>
            </div>
            <div className=" w-full flex-col justify-center items-center gap-2.5 flex">
              <section className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <h2 className="text-left text-[#3c3b3e] text-[32px] font-semibold font-openSans">How to Soothe a Crying Baby</h2>
                <p className=" 100:w-full lg:w-[664px] text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  Discover effective methods to calm a crying baby. From understanding why babies cry to practical techniques for soothing, this article offers comprehensive guidance for new parents.{" "}
                </p>
              </section>
            </div>
            <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
              <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Understanding Why Babies Cry</h4>
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  Babies cry as their primary way of communicating their needs. Understanding the common reasons for crying can help you address their needs more effectively.{" "}
                </p>
              </div>
            </div>
            <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
              <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Common Reasons Babies Cry:</h4>
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  <span className=" font-semibold">1. Hunger:</span> Feeding your baby might be the solution.{" "}
                </p>
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  <span className=" font-semibold">2. Dirty Diaper:</span>
                  Check and change their diaper if needed.{" "}
                </p>
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  <span className=" font-semibold">3. Tiredness: </span>
                  Babies can cry when they are sleepy but can’t fall asleep.{" "}
                </p>{" "}
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  <span className=" font-semibold">4. Discomfort:</span>
                  Check for tight clothing, temperature, or any other discomfort.{" "}
                </p>
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  <span className=" font-semibold">5. Gas or Colic:</span>
                  Babies may cry due to digestive discomfort.{" "}
                  {readMore2 ? (
                    <></>
                  ) : (
                    <span className="text-[#fe8734] cursor-pointer font-semibold underline" onClick={() => setReadMore2(true)}>
                      Read more
                    </span>
                  )}
                </p>
              </div>
            </div>
            {readMore2 && (
              <>
                <div className="w-full flex-col justify-start items-center gap-[10px] inline-flex">
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Effective Soothing Techniques</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Here are practical methods to soothe your crying baby: </p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">1. Swaddling:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      Wrapping your baby snugly in a blanket can provide comfort and security. Make sure the swaddle is not too tight and allows some movement for the baby.{" "}
                    </p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">2. Gentle Rocking:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Rocking your baby in your arms or a rocking chair can mimic the movement they felt in the womb and help calm them. </p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">3. White Noise:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Use a white noise machine or a recording of soothing sounds to replicate the constant background noise of the womb. </p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">4. Pacifier:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Sucking can be very calming for babies. Offer a pacifier to help soothe them.</p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">5. Bathing:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">A warm bath can help relax a baby and soothe their discomfort.</p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">6. Gentle Touch:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Massaging your baby’s back, arms, or legs can provide comfort. Skin-to-skin contact is also very soothing for infants.</p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">7. Feeding:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">If it’s been a while since their last feeding, offering the breast or bottle might calm them.</p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">8. Change of Scenery:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      Sometimes, a change of environment can help. Taking your baby for a walk outside or moving to a different room might distract and calm them.
                    </p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">9. Burping:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      If your baby is crying due to gas, burping them can help relieve the discomfort. Hold them upright and gently pat their back. Sometimes, a change of environment can help. Taking your baby for a walk outside or moving to a
                      different room might distract and calm them.
                    </p>
                  </div>
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-base font-semibold font-openSans">10. Singing or Talking:</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Your voice can be very soothing to your baby. Sing a lullaby or talk softly to them to provide comfort.</p>
                  </div>
                </div>
                <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Recognizing When to Seek Help</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      If your baby’s crying is excessive or you suspect something more serious might be wrong, don’t hesitate to contact your pediatrician. Persistent crying could be a sign of an underlying medical issue that needs attention.{" "}
                    </p>
                  </div>
                </div>
                <div className="w-full flex-col justify-start items-center gap-[10px] inline-flex">
                  <div className="md:w-[664px] py-[16px] flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Self-Care for Parents</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Taking care of a crying baby can be stressful. Remember to take care of yourself too:</p>
                    <ul className=" py-6 list-disc flex flex-col justify-start items-start  text-[#6b6a70] gap-[10px] text-base font-normal font-openSans leading-[25px]">
                      <li>
                        <span className=" font-semibold">Ask for Help:</span> Don’t hesitate to ask friends or family for support.
                      </li>
                      <li>
                        <span className=" font-semibold">Take Breaks:</span> If you feel overwhelmed, it’s okay to take a short break while ensuring your baby is in a safe place.
                      </li>
                      <li>
                        <span className=" font-semibold">Rest:</span> Try to rest when your baby sleeps to recharge your energy.{" "}
                        <span className="text-[#fe8734] cursor-pointer font-semibold underline" onClick={() => setReadMore2(false)}>
                          Show Less
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="lg:w-[1000px] 100:w-full h-fit  p-2.5 flex-col justify-center items-center inline-flex">
          <div className=" w-full">
            <img className="w-full" src={resource3} alt="" />
          </div>
          <div className=" 100:w-[90%] mt-[-56px] z-20 lg:w-[950px] h-fit px-8 py-6 bg-[#f0faff] rounded-[20px] flex-col justify-center items-center gap-4 inline-flex">
            <div className=" w-full flex justify-end items-end">
              <h3 className="text-[#86858b] text-sm font-semibold font-openSans">June 25, 2024 </h3>
            </div>
            <div className=" w-full flex-col justify-center items-center gap-2.5 flex">
              <section className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <h2 className="text-left text-[#3c3b3e] text-[32px] font-semibold font-openSans">Potty Training 101: A Complete Guide</h2>
                <p className=" 100:w-full lg:w-[664px] text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  Get a comprehensive guide on how to potty train your toddler. Covering signs of readiness, step-by-step instructions, and troubleshooting tips, this article will help make potty training a smoother process for both you and your
                  child.{" "}
                </p>
              </section>
            </div>
            <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
              <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Signs of Readiness</h4>
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">Potty training is most successful when your child shows signs of being ready. Here are key indicators: </p>
              </div>
            </div>
            <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
              <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <ol className=" list-decimal text-[#3c3b3e] text-xl font-semibold font-openSans">
                  <li>
                    Physical Readiness:
                    <ul className="self-stretch mt-[10px] space-y-2 list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      <li>Stays dry for at least 2 hours at a time.</li>
                      <li>Has regular, predictable bowel movements.</li>
                      <li>Shows discomfort with dirty diapers.</li>
                    </ul>
                  </li>
                  <li className="mt-[32px]">
                    Behavioral Readiness:
                    <ul className="self-stretch mt-[10px] space-y-2 list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      <li>Can follow simple instructions.</li>
                      <li>Shows interest in using the potty.</li>
                      <li>Can communicate the need to go.</li>
                    </ul>
                  </li>
                  <li className="mt-[32px]">
                    Emotional Readiness:
                    <ul className="self-stretch mt-[10px] space-y-2 list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      <li>Expresses a desire to be more independent.</li>
                      <li>Responds positively to praise and encouragement.</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
            <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
              <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Recognizing When to Seek Help</h4>
                <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                  If your baby’s crying is excessive or you suspect something more serious might be wrong, don’t hesitate to contact your pediatrician. Persistent crying could be a sign of an underlying medical issue that needs attention.{" "}
                  {readMore3 ? (
                    <></>
                  ) : (
                    <span className="text-[#fe8734] cursor-pointer font-semibold underline" onClick={() => setReadMore3(true)}>
                      Read more
                    </span>
                  )}
                </p>
              </div>
            </div>
            {readMore3 && (
              <>
                <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-[16px] inline-flex">
                    <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Step-by-Step Instructions</h4>

                    <ol className=" list-decimal text-[#3c3b3e] flex flex-col justify-start items-start gap-[40px] text-base font-semibold font-openSans">
                      <li>
                        Introduce the Concept:
                        <ul className="self-stretch   list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Begin by explaining what the potty is for. Let your child watch family members using the toilet and explain the process in simple terms.</li>
                        </ul>
                      </li>
                      <li>
                        Choose the Right Equipment:
                        <ul className="self-stretch  list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Select a potty chair or a seat reducer for the regular toilet. Make sure it’s comfortable and easy for your child to use.</li>
                        </ul>
                      </li>
                      <li>
                        Establish a Routine:
                        <ul className="self-stretch  s list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Encourage your child to sit on the potty at regular intervals, such as after meals or before bedtime. Make it a part of their daily routine.</li>
                        </ul>
                      </li>
                      <li>
                        Demonstrate and Teach:
                        <ul className="self-stretch  s list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Show your child how to use the potty. If possible, use a favorite doll or stuffed animal to demonstrate the steps.</li>
                        </ul>
                      </li>
                      <li>
                        Encourage and Praise:
                        <ul className="self-stretch  s list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Praise your child for sitting on the potty, even if they don’t go. Positive reinforcement will build their confidence and motivation.</li>
                        </ul>
                      </li>{" "}
                      <li>
                        Teach Hygiene:
                        <ul className="self-stretch  s list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Show your child how to wipe properly (front to back for girls), flush the toilet, and wash their hands thoroughly.</li>
                        </ul>
                      </li>
                      <li>
                        Gradual Transition:
                        <ul className="self-stretch  s list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Transition from diapers to training pants or regular underwear. Explain that these are for “big kids” and encourage their use.</li>
                        </ul>
                      </li>
                      <li>
                        Be Patient and Supportive:
                        <ul className="self-stretch  s list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Accidents will happen. Respond with patience and reassurance rather than frustration. Encourage your child to try again.</li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-[16px] inline-flex">
                    <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Troubleshooting Tips</h4>

                    <ol className=" list-decimal text-[#3c3b3e] flex flex-col justify-start items-start gap-[40px] text-base font-semibold font-openSans">
                      <li>
                        Regression:
                        <ul className="self-stretch   list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>If your child regresses, stay calm. This can be due to stress or changes in routine. Reinforce positive behaviors and be patient.</li>
                        </ul>
                      </li>
                      <li>
                        Resistance:
                        <ul className="self-stretch   list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>If your child resists potty training, take a break and try again later. Forcing the process can create negative associations.</li>
                        </ul>
                      </li>{" "}
                      <li>
                        Nighttime Training:
                        <ul className="self-stretch   list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Nighttime dryness often takes longer to achieve. Use overnight training pants until your child consistently wakes up dry.</li>
                        </ul>
                      </li>
                      <li>
                        Rewards:
                        <ul className="self-stretch   list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Consider using a reward system, such as stickers or small treats, to motivate your child. Ensure rewards are immediate and consistent.</li>
                        </ul>
                      </li>
                      <li>
                        Stay Consistent:
                        <ul className="self-stretch   list-disc text-[#6b6a70] text-base font-normal font-openSans leading-[28px]">
                          <li>Consistency is key. Ensure all caregivers are following the same potty training approach to avoid confusion for your child.</li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">When to Seek Help</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      If your child is over three years old and still not showing signs of readiness, or if potty training is causing significant stress, consult your pediatrician for advice and support.{" "}
                    </p>
                  </div>
                </div>
                <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                  <div className="md:w-[664px] py-6 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className=" text-[#3c3b3e] text-xl font-semibold font-openSans">Encouragement for Parents</h4>
                    <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">
                      Remember, every child is different. Potty training can be a challenging process, but with patience, consistency, and encouragement, your child will eventually succeed. Celebrate the small victories along the way and stay
                      positive.{" "}
                      <span className="text-[#fe8734] cursor-pointer font-semibold underline" onClick={() => setReadMore3(false)}>
                        Show Less
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
      {showButton && (
        <>
        <div onClick={scrollToTop} className="h-10 shadow z-40 100:right-[40px] top-[80vh] lg:right-[80px] p-2.5 cursor-pointer fixed bg-[#dff4ff] rounded-[100px] justify-center items-center gap-2.5 inline-flex">
            <img src={up} alt="arrow" />
          </div>
        </>
      )}
    </>
  );
}

export default ExpertAdvice;
