import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import pic from "../Images/photos/form.png";
import { ReactComponent as Star } from "../Images/svg/star.svg";
import cancel from "../Images/svg/Cancel.svg";
import tick from "../Images/svg/ticked.svg";
import PrivacyParticipant from "../PrivacyParticipant";
// import axios from "axios";

const earlyBird = [
  {
    id: 1,
    title: "Dr. Ayodele Renner: Simplifying Introduction of Solids to Babies",
    time: "16th November, 12pm to 1pm",
    amount: 15500,
  },
  {
    id: 2,
    title: "Dr. Cole-Adeife: Making Skin Care for Babies Smoother",
    time: "16th November, 2pm to 3pm",
    amount: 15500,
  },
  {
    id: 3,
    title: "Sisi Yemmie: Making the Most of Feeding Under-5s",
    time: "17th November, 1 to 2 pm",
    amount: 15500,
  },
  {
    id: 4,
    title: "Dr. Chinny: Making Breastfeeding Work for You",
    time: "17th November 3 to 4 pm",
    amount: 15500,
  },
];

const regular = [
  {
    id: 1,
    title: "Dr. Ayodele Renner: Simplifying Introduction of Solids to Babies",
    time: "16th November, 12pm to 1pm",
    amount: 25500,
  },
  {
    id: 2,
    title: "Dr. Cole-Adeife: Making Skin Care for Babies Smoother",
    time: "16th November, 2pm to 3pm",
    amount: 25500,
  },
  {
    id: 3,
    title: "Sisi Yemmie: Making the Most of Feeding Under-5s",
    time: "17th November, 1 to 2 pm",
    amount: 25500,
  },
  {
    id: 4,
    title: "Dr. Chinny: Making Breastfeeding Work for You",
    time: "17th November 3 to 4 pm",
    amount: 25500,
  },
];

function Participant() {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  // const [masterClass, setMasterClass] = useState(false);
  const [masterClass, setMasterClass] = useState(null);
  const [masterClassSelection, setMasterClassSelection] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [save, setSave] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showMasterClassOptions, setShowMasterClassOptions] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [showEarlyBird, setShowEarlyBird] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(masterClass);

    if (!privacyAccepted && masterClass === true) {
      setErrorMessage("You must agree to the terms of use.");
      return;
    }

    const trimmedMasterClassSelection = masterClassSelection.trim();
    const selectedClass = showEarlyBird ? earlyBird.find((cls) => cls.title.trim().toLowerCase() === trimmedMasterClassSelection.toLowerCase()) : regular.find((cls) => cls.title.trim().toLowerCase() === trimmedMasterClassSelection.toLowerCase());

    if (!selectedClass && masterClass === true) {
      setErrorMessage("Please select a valid master class.");
      return;
    }

    const class_amount = masterClass ? selectedClass.amount : 0;
    setAmount(class_amount);
    setErrorMessage("");
    setLoading(true);

    if (masterClass === true) {
      const data = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        email: email.trim(),
        phone: phoneNumber.trim(),
        amount: class_amount,
        selected_master_class: trimmedMasterClassSelection,
        master_class: "YES",
      };

      console.log(data);

      try {
        await fetch("https://www.thebabyconvention.com/wbe/api/add_participants.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then(async (res) => {
          const responseData = await res.json();

          console.log("response", responseData);

          if (responseData.responsecode === "200") {
            setResponseMessage(responseData.responsemessage);
            redirectToUrl(responseData.url);
          } else {
            setErrorMessage(res.message || "Something went wrong.");
          }
        });
      } finally {
        // catch (error) {
        //   console.error("Fetch error:", error);
        //   setErrorMessage("Failed to submit data. Please try again later.");
        // }
        setLoading(false);
      }
    } else {
      const data = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        email: email.trim(),
        phone: phoneNumber.trim(),
        master_class: "NO",
        selected_master_class: "No Master Class",
        amount: "",
      };

      console.log(data);
      try {
        await fetch("https://www.thebabyconvention.com/wbe/api/add_participants.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then(async (res) => {
          const responseData = await res.json();

          console.log("response", responseData);

          if (responseData.responsecode === "200") {
            setResponseMessage(responseData.responsemessage);
          } else {
            setErrorMessage(res.message || "Something went wrong.");
          }
        });
      } finally {
        // catch (error) {
        //   console.error("Fetch error:", error);
        //   setErrorMessage("Failed to submit data. Please try again later.");
        // }
        setLoading(false);
      }
    }
  };

  const redirectToUrl = (responseData) => {
    window.location.href = responseData;
  };
  return (
    <>
      <div className=" w-full flex justify-between items-start">
        <div style={{ backgroundImage: ` url(${pic})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "rgba(254, 135, 52) 60%" }} className="100:hidden xl:flex w-[100vh] fixed top-0 left-0  h-full">
          <div className=" w-full bg-[#FE8734]/60 h-full">
            <div className=" w-full   flex justify-end items-end h-full" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.7) 100%)` }}>
              <div className="p-10 sm:p-[50px] md:p-[75px] lg:p-[50px] w-full gap-[48px] flex flex-col justify-start items-start">
                <Star />
                <div className="flex-col justify-start items-center gap-6 inline-flex">
                  <h1 className="self-stretch text-white text-[32px] sm:text-[40px] md:text-[48px] lg:text-[64px] font-semibold font-playfair leading-tight">Join the Baby Convention Today!</h1>
                  <p className="self-stretch text-[#cfcfd2] text-lg font-normal font-openSans leading-[27px]">
                    Don't miss out on the ultimate event for expectant parents and families with young children! Our Baby Convention offers a wealth of resources, expert advice, product showcases, and fun activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" h-fit pb-[50px] flex flex-col justify-start items-center gap-[62px] w-full 100:pl-[40px] xl:pl-[105vh] 1xl:pl-[110vh] pr-[40px]">
          <div className=" h-[100px] left-0 top-0 bg-white/40 w-full 100:px-[50px] xl:px-0  100:fixed xl:static   flex  justify-between items-center">
            <Link to="/options">
              <div className="w-fit flex justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={arrow} alt="back" />
                </div>
                <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
              </div>
            </Link>
            <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
              <img className="w-full" src={logo} alt="The Baby Convention" />
            </div>
            <div className="w-[60px] 100:hidden md:block"></div>
          </div>
          <div className="sm:w-[450px] 100:w-full 100:mt-[100px] xl:mt-0 flex-col justify-start items-start gap-8 inline-flex">
            <h3 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-playfair">Sign up</h3>

            <form onSubmit={handleSubmit} className=" w-full   pb-[10px]  flex-col justify-start items-start gap-4 inline-flex">
              <div className="100:flex-col phone:flex-row w-full flex justify-center gap-[32px]">
                <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="First name">
                  First name
                  <input
                    type="name"
                    placeholder="First name"
                    className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                    id="first_name"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </label>
                <label className="flex-col w-full  justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Last name">
                  Last name
                  <input
                    type="name"
                    placeholder="Last name"
                    className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                    id="last_name"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </label>
              </div>
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                Email
                <input
                  type="email"
                  placeholder="Email Address"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Phone number">
                Phone number
                <input
                  type="tel"
                  placeholder="Phone number"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="phone_number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </label>

              <label className="flex-col w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Master class">
                Would you like to attend a Master class?
                <label className="flex justify-start items-center gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor="yes">
                  <input
                    className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                    type="radio"
                    name="master_class"
                    id="master_class"
                    onClick={() => setShowMasterClassOptions(true)}
                    value="yes"
                    checked={masterClass === true}
                    // checked={masterClass}
                    onChange={() => setMasterClass(true)}
                  />{" "}
                  Yes
                </label>
                <label className="flex justify-start items-center gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor="no">
                  <input
                    className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                    type="radio"
                    id="master_class"
                    name="master_class"
                    onClick={() => setShowMasterClassOptions(false)}
                    value="no"
                    // checked={!masterClass}
                    checked={masterClass === false}
                    onChange={() => setMasterClass(false)}
                  />{" "}
                  No
                </label>
              </label>

              {showMasterClassOptions && (
                <>
                  <label className="flex-col  w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="attend">
                    Which master class would you like to attend?
                    {showEarlyBird ? (
                      <label className="flex-col  w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="attend">
                        Early Bird Discounts Available from (September 1st to October 15th)
                        {earlyBird.map((item) => (
                          <label className=" w-full flex justify-start items-start gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor={item.title}>
                            <input
                              type="radio"
                              className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                              name="early_bird"
                              value={item.title}
                              onChange={(e) => setMasterClassSelection(e.target.value)}
                              required
                            />
                            <div className=" w-full flex flex-col gap-1 items-start justify-start">
                              <span className=" w-full text-wrap">{item.title}</span>
                              <span className=" w-full text-wrap">{item.time}</span>
                              <span className="w-full font-medium text-wrap">₦{Number(item.amount).toLocaleString()}</span>
                            </div>
                          </label>
                        ))}
                      </label>
                    ) : (
                      <label className="flex-col  w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="attend">
                        Regular Fee Available from (October 16th to November 1st)
                        {regular.map((item) => (
                          <label className=" w-full flex justify-start items-start gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor={item.title}>
                            <input type="radio" className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]" id={item.id} name="regular" />
                            {/* <span className=" w-full text-wrap">{item.title}</span> */}
                            <div className=" w-full flex flex-col gap-1 items-start justify-start">
                              <span className=" w-full text-wrap">{item.title}</span>
                              <span className=" w-full text-wrap">{item.time}</span>
                              <span className="w-full font-medium text-wrap">₦{Number(item.amount).toLocaleString()}</span>
                            </div>
                          </label>
                        ))}
                      </label>
                    )}
                  </label>
                </>
              )}

              {showMasterClassOptions && (
                <label className="w-full justify-start items-center gap-[12px] inline-flex text-[#86858b] text-base font-normal font-openSans" htmlFor="Message">
                  <input className="bg-white  rounded-[6px] h-[20px] w-[20px] appearance-none checked:bg-[#fe8734]   border border-[#aeaeb2]" type="checkbox" checked={privacyAccepted} onChange={() => setPrivacyAccepted(!privacyAccepted)} />
                  <p className=" ">
                    You agree to our friendly{" "}
                    <span onClick={() => setPrivacy(true)} className=" cursor-pointer underline">
                      Terms of use
                    </span>{" "}
                    .
                  </p>
                </label>
              )}
              {errorMessage && <p className="w-full flex justify-center items-center text-red-500">{errorMessage}</p>}
              {responseMessage && <p className="w-full flex justify-center items-center text-green-500">{responseMessage}</p>}

              <button type="submit" className="w-full ">
                <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">{isLoading ? "Submitting..." : " Submit"}</div>
              </button>
            </form>
          </div>
        </div>
      </div>
      {save && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="w-full px-2 justify-end items-center inline-flex">
                <div onClick={() => setSave(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>

              <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
                <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                  <img src={tick} alt="tick" />
                </div>
                <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                  <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Successful</h3>
                  <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{responseMessage}</p>
                </div>
                <div className=" w-full flex justify-center items-center">
                  <button>
                    <div
                      onClick={() => {
                        setSave(false);
                        setLoading(false);
                        // formik.resetForm(); // Reset form fields
                        window.scrollTo(0, 0);
                        navigate("/");
                      }}
                      className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"
                    >
                      Ok
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {privacy && <PrivacyParticipant setPrivacy={setPrivacy} privacy={privacy} />}
    </>
  );
}

export default Participant;
