import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import pic from "../Images/photos/form.png";
import { ReactComponent as Star } from "../Images/svg/star.svg";
import { ReactComponent as View } from "../Images/svg/eye.svg";
import { ReactComponent as ViewOff } from "../Images/svg/view-off.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
// import axios from "axios";

function Login() {
  const validationSchema = Yup.object({
    username: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = {
        username: values.username,
        password: values.password,
      };

      // try {
      await fetch("https://thebabyconvention.com/wbe/api/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }).then(async (res) => {
        setLoading(false);
        const responseData = await res.json();
        if (responseData.data) {
          console.log("is response", responseData.data);
          setResponseMessage(responseData.responsemessage || "Login successful!");
          localStorage.setItem("babyConv", JSON.stringify(responseData.data));
          navigate("/admin/user-management");
        } else {
          setResponseMessage("Login failed. Please try again.");
        }
      });
      //     const res = await fetch("https://thebabyconvention.com/wbe/api/login.php", {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(formData),
      //     });
      //     console.log(formData);
      //     setLoading(false);
      //     if (res.ok) {
      //       console.log(res.data);
      //       localStorage.setItem("", res.data.data);
      //       const responseData = await res.json();
      //       setResponseMessage(responseData.responsemessage || "Login successful!");
      //       navigate("/admin/user-management");
      //     } else {
      //       setResponseMessage("Login failed. Please try again.");
      //     }
      //   } catch (error) {
      //     setLoading(false);
      //     setResponseMessage("An error occurred. Please try again later.");
      //     }
    },
  });

  const [hide, setHide] = useState(false);
  return (
    <>
      <div className=" w-full flex justify-between items-start">
        <div style={{ backgroundImage: ` url(${pic})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "rgba(254, 135, 52) 60%" }} className="100:hidden xl:flex w-[100vh] fixed top-0 left-0  h-full">
          <div className=" w-full bg-[#FE8734]/60 h-full">
            <div className=" w-full xl:justify-center xl:items-center  flex  h-full" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.7) 100%)` }}>
              <div className=" w-[80%] gap-[48px] flex flex-col justify-start items-start">
                <Star className="  " />
                <div className="flex-col justify-start items-center gap-6 inline-flex">
                  <h1 className="self-stretch text-white 100:text-[32px] sm:text-[40px] md:text-[48px] lg:text-[64px] font-semibold font-playfair leading-tight">Join the Baby Convention Today!</h1>
                  <p className="self-stretch text-[#cfcfd2] text-lg font-normal font-openSans leading-[27px]">
                    Don't miss out on the ultimate event for expectant parents and families with young children! Our Baby Convention offers a wealth of resources, expert advice, product showcases, and fun activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" h-fit pb-[50px] flex flex-col justify-start items-center gap-[62px] w-full 100:pl-[40px] xl:pl-[105vh] 1xl:pl-[110vh] pr-[40px]">
          <div className=" h-[100px] left-0 top-0 bg-white/40 w-full 100:px-[50px] xl:px-0  100:fixed xl:static   flex  justify-between items-center">
            <Link to="/home">
              <div className="w-fit flex justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={arrow} alt="back" />
                </div>
                <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
              </div>
            </Link>
            <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
              <img className="w-full" src={logo} alt="The Baby Convention" />
            </div>
            <div className="w-[60px] 100:hidden md:block"></div>
          </div>
          <div className="sm:w-[450px] 100:w-full 100:mt-[100px] xl:mt-0 flex-col justify-start items-start gap-8 inline-flex">
            <h3 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-playfair">Login</h3>
            <form onSubmit={formik.handleSubmit} className=" w-full   pb-[10px]  flex-col justify-start items-start gap-4 inline-flex">
              {responseMessage && <div className=" text-red-500">{responseMessage}</div>}
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                Email
                <input
                  type="email"
                  placeholder="Email Address"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="username"
                  {...formik.getFieldProps("username")}
                />
                {formik.touched.username && formik.errors.username ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.username}</div> : null}
              </label>
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="password">
                Password
                <div className="outline-[#fe8734] pr-3.5 h-[43px] w-full flex justify-between items-center  bg-white rounded-[20px] shadow border border-[#aeaeb2] ">
                  <input type={hide ? "text" : "password"} placeholder="Enter Password" className="pl-4 pr-3.5 outline-none bg-transparent rounded-[20px] py-3 w-full h-full" id="password" {...formik.getFieldProps("password")} />
                  <span onClick={() => setHide(!hide)} className=" cursor-pointer">
                    {hide ? <View /> : <ViewOff />}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.password}</div> : null}
              </label>
              <button type="submit" className="w-full mt-[24px]">
                <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"> {isLoading ? "Submitting..." : " Submit"} </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
